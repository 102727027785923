<template>
    <div id="theWholeCard">
      
    <div :id="product.id" class="carousel carousel-dark slide" data-bs-pause="false">
    <div class="carousel-inner">
      <div  class="carousel-item imagePart" v-for="image in product.bunnyImageUrls" :key="image" :class="image === product.bunnyImageUrls[0] ? 'active' : ''">
        
        <router-link :to="productDetail" class="imageContainer">
          <img :src="image" class="img-fluid rounded d-block w-100" :alt="product.id"/>
          <p class="top-right">{{1 + product.bunnyImageUrls.indexOf(image)}} / {{product.bunnyImageUrls.length}}</p>
        </router-link>
          
      </div>
    </div>
    <button class="carousel-control-prev" type="button" :data-bs-target="'#' + product.id" data-bs-slide="prev">
      <span class="carousel-control-prev-icon bclour" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" :data-bs-target="'#' + product.id" data-bs-slide="next">
      <span class="carousel-control-next-icon bclour" aria-hidden="true"></span>
      <span class="visually-hidden ">Next</span>
    </button>
  </div>
  
  
      <div class="">
        <p class="card-title">
          Owner: <span class="fst-itali">{{ product.ownerName }}</span>
        </p>
        <p class="card-title">
          Owner Contacts: <span class="fst-itali">{{ product.ownerPhoneNumber }}</span>
        </p>
        <p class="card-title">
          productName: <span class="fst-itali">{{ product.productName }}</span>
        </p>
        <p> 
          <strong>{{ product.price.toLocaleString('UG', { style: 'currency', currency: 'UGX' }) }} UGX</strong> / month
        </p>
        
      </div>
      <div class="approved">
        <p>Approved: <span>{{approvedStatus}}</span></p>
        <button class="btn btn-primary" @click="marketplaceApproveProduct">{{approveProductStat}}</button>
        
      </div>
      <div class="book">
        <p>Sold: <span>{{product.sold}}</span></p>
        <button class="btn btn-primary" @click="marketplaceSellProduct">{{soldProductStat}}</button>
      </div>
      <div v-if="!updatepropertyHasVideo">
          <form @submit.prevent="addVideoIdToMarketProduct">
            <div class="form-group">
            <label for="videoId">Add video Id</label>
            <input type="text" class="form-control" id="videoId" v-model.trim="videoId">
          </div>
          <button class="btn btn-primary mt-1" type="submit">submit</button>
          </form>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ["product"],
    data() {
      return {
        approvedStat: this.product.approved,
        soldStat: this.product.sold,
        videoId: "",
        propertyHasVideo: this.product.videoId
      }
    },
    computed: {
      productLink() {
        return "/marketplace/product/" + this.product.id + '/buy';
      },
      productDetail() {
        return "/marketplace/product/" + this.product.id;
      },
      approveProductStat() {
        if(this.approvedStat) {
          return 'Disapprove Product'
        } else {
          return 'Approve Product'
        }
      },
      soldProductStat() {
        if(this.soldStat) {
          return 'make it available'
        } else {
          return 'sold'
        }
      },
      approvedStatus() {
        return this.product.approved
      },
      updatepropertyHasVideo() {
        return this.propertyHasVideo
      }
  
    },
    methods: {
      async marketplaceApproveProduct() {
        await this.$store.dispatch('admin/approveProduct', {id: this.product.id, approved: this.product.approved})
        this.approvedStat = !this.approvedStat
      },
      async marketplaceSellProduct() {
        // console.log("this is the id of the product", this.product.id)
        await this.$store.dispatch('admin/sellProduct', {id: this.product.id, sold: this.product.sold})
        this.soldStat = !this.soldStat
      },
      async addVideoIdToMarketProduct() {
        await this.$store.dispatch('admin/addVideoIdToMarketProduct', {id: this.product.id, videoId: this.videoId})
        this.videoId = ""
        this.propertyHasVideo = false
      }
    },
  };
  </script>
  
  <style scoped>
  #theWholeCard {
    max-height: 120vh;
    max-width: 25rem;
  }
  span {
    font-weight: bold;
  }
  
  div .imagePart {
    width: 100%;
    height: 20rem;
  }
  p {
    margin-bottom: .4rem;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .imageContainer {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
    background-color: rgb(65, 59, 59);
    padding-left: .3rem;
    padding-right: .3rem;
    border-radius: .2rem;
  }
  
  a {
    text-decoration: none;
  }
  
  .bclour {
    background-color: black;
    border-radius: .3rem;
  }
  
  .approved {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
  }
  
  .book {
    display: flex;
    justify-content: space-between;
    margin-bottom: .7rem;
  }
  
  </style>