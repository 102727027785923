<template>
    <div>
      <section>
        <!-- <div id="filter">
          <base-button class="mt-3" @click="toggleFilter">Filter</base-button>
        </div>
        
        <rental-filter v-if="showFilter" @search="searchCriteria"></rental-filter> -->
        <!-- Button trigger modal -->
        <div id="filter">
          <button
            type="button"
            class="btn btn-outline-secondary dropdown-toggle mt-3"
            data-bs-toggle="modal"
            data-bs-target="#filterModal"
            @click="toggleFilter"
          >
            Filters
          </button>
        </div>
  
        <!-- Modal -->
        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Filter By</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <rental-filter @search="searchCriteria"></rental-filter>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="filteredProducts.length >= 1">
        <div class="container">
          <div class="row justify-content-around">
            <div v-if="isLoading">
              <base-spinner></base-spinner>
            </div>
            <admin-product-item
              class="col-sm-5 col-md-4 mt-3 m-1"
              v-for="product in filteredProducts"
              :key="product.id"
              :product="product"
            ></admin-product-item>
          </div>
        </div>
      </section>
      <section v-else>
        <h1>Coming Soon</h1>
      </section>
    </div>
  </template>
  
  <script>
  import AdminProductItem from "../../../components/admin/marketplace/AdminProductItem.vue"
  import RentalFilter from "../../../components/rentals/RentalFilter.vue";
  export default {
    components: {
        AdminProductItem,
      RentalFilter,
    },
    data() {
      return {
        showFilter: false,
        isLoading: false,
        price: 100000000,
      };
    },
    computed: {
      filteredProducts() {
        const products = this.$store.getters["admin/adminProducts"];
        // console.log('The products', products)
        return products.filter((product) => {
          if (
            !this.isLoading &&
            product.price <= this.price
          ) {
            // console.log('inside inside',  'and inside the rental', product)
            return true;
          }
          return false;
        });
      },
    },
    methods: {
      toggleFilter() {
        this.showFilter = !this.showFilter;
      },
      async loadProducts() {
        this.isLoading = true;
        await this.$store.dispatch("admin/loadAdminProducts");
        this.isLoading = false;
      },
      searchCriteria(val) {
        this.location = val.location;
        (this.price = val.price), (this.houseType = val.houseType);
      },
    },
    async created() {
      this.loadProducts()
    },
    // updated() {
    //   this.loadRentals();
    // }
  };
  </script>
  
  <style scoped>
  #filter {
    text-align: center;
    margin-bottom: 0.3rem;
  }
  </style>